<template>
  <div>
    <a-form style="margin: 40px auto 0;">
      <result title="Submitted successfully" :is-success="true" description="Expected to arrive within two hours">
        <div class="information">
          <a-row>
            <a-col :sm="8" :xs="24">To Address：</a-col>
            <a-col :sm="16" :xs="24">{{toAddress}}</a-col>
          </a-row>
          <a-row>
            <a-col :sm="8" :xs="24">Amount：</a-col>
            <a-col :sm="16" :xs="24"><span class="money">{{amount}}</span> USDT</a-col>
          </a-row>
          <a-row>
            <a-col :sm="8" :xs="24">Remark：</a-col>
            <a-col :sm="16" :xs="24">{{remark}}</a-col>
          </a-row>
        </div>
        <div slot="action">
          <a-button type="primary" @click="finish">Initiate the transaction again</a-button>
          <a-button style="margin-left: 8px" @click="toOrderList">View bill</a-button>
        </div>
      </result>
    </a-form>
  </div>
</template>

<script>
  import Result from '../result/Result'

  export default {
    name: "Step3",
    components: {
      Result
    },
    data () {
      return {
        loading: false,
        toAddress:'',
        amount:null,
        remark:''
      }
    },
    created () {
      const fileListInfo = sessionStorage.getItem('submitAgentPayParams');
      const dataInfo = JSON.parse(fileListInfo);
      this.toAddress = dataInfo.toAddress;
      this.amount = dataInfo.amount;
      this.remark = dataInfo.remark
      sessionStorage.setItem('submitAgentPayParams', '');
    },
    methods: {
      finish () {
        this.$emit('finish')
      },
      toOrderList () {
        this.$router.push('/agentpay/agentpayList')
      }
    }
  }
</script>
<style lang="less" scoped>
  .information {
    line-height: 22px;

    .ant-row:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  .money {
    font-family: "Helvetica Neue",sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 14px;
  }
</style>