<template>
  <a-card :bordered="false">

    <!-- 操作按钮区域 -->
    <div class="table-operator" style="border-top: 5px">
      <a-button @click="handleAdd" type="primary" icon="plus">添加</a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <a-table
        ref="table"
        bordered
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        @change="handleTableChange">

        <template slot="status" slot-scope="status">
          <a-tag v-if="status=='normal'" color="green">正常</a-tag>
          <a-tag v-if="status=='inactivated'" color="orange">未激活</a-tag>
          <a-tag v-if="status=='unavailable'" color="red">禁用</a-tag>
        </template>

        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)" >编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定发送激活验证邮件吗?" @confirm="() => handleaAtivateMail(record.merchantNo)">
            <a>重新验证</a>
          </a-popconfirm>
         <!--
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link">
              更多 <a-icon type="down"/>
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;" @click="handleDetail(record)">详情</a>
              </a-menu-item>

              <a-menu-item v-if="record.status=='normal'">
                <a-popconfirm title="确定禁用吗?" @confirm="() => handleFrozen(record.id,2,record.merchantName)">
                  <a>禁用</a>
                </a-popconfirm>
              </a-menu-item>

              <a-menu-item v-if="record.status=='unavailable'">
                <a-popconfirm title="确定解冻吗?" @confirm="() => handleFrozen(record.id,1,record.merchantName)">
                  <a>解冻</a>
                </a-popconfirm>
              </a-menu-item>

            </a-menu>
          </a-dropdown>-->
        </span>


      </a-table>
    </div>
    <!-- table区域-end -->
    <merchant-modal ref="modalForm" @ok="modalFormOk"></merchant-modal>
    <password-modal ref="passwordmodal" @ok="passwordModalOk"></password-modal>

  </a-card>
</template>

<script>
  import MerchantModal from './modules/MerchantModal'
  import PasswordModal from './modules/PasswordModal'
  import {getFileAccessHttpUrl} from '@/api/manage';
  import {frozenBatch,activateMail} from '@/api/api'
  import {ListMixin} from '@/mixins/ListMixin'
  import {filterObj} from '@/utils/util';
  import Vue from 'vue'
  import {ACCESS_TOKEN } from "@/store/mutation-types"

  export default {
    name: "MerchantList",
    mixins: [ListMixin],
    components: {
      MerchantModal,
      PasswordModal
    },
    data() {
      return {
        description: '商户管理',
        queryParam: {},
        columns: [
          {
            title: '商户号',
            align:"left",
            dataIndex: 'merchantNo',
            scopedSlots: { customRender: 'merchantNo' },
          },
          {
            title: '商户名称',
            align:"center",
            dataIndex: 'merchantName',
            scopedSlots: { customRender: 'merchantName' },
          },
          {
            title: '状态',
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' },
            align:"center",
          },
          {
            title: '登录账号',
            dataIndex: 'loginEmail',
            align:"center",
          },
          {
            title: '创建时间',
            dataIndex: 'createDate',
            scopedSlots: {customRender: "createDate"},
            align:"center",
          },
          {
            title: '操作',
            scopedSlots: {customRender: 'action'},
            align: "center",
            width: 170
          }
        ],
        url: {
          syncUser: "/process/extActProcess/doSyncUser",
          list: "/sys/merchant/merchantList",
          delete: "/sys/user/delete",
          deleteBatch: "/sys/user/deleteBatch",
          exportXlsUrl: "/sys/user/exportXls",
          importExcelUrl: "sys/user/importExcel",
        },
      }
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      }
    },
    methods: {
      getQueryParams(){
        var param = Object.assign({}, this.queryParam,this.isorter);
        param.token = Vue.ls.get(ACCESS_TOKEN);
        param.pageNo = this.ipagination.current;
        param.pageSize = this.ipagination.pageSize;
        if (this.superQueryParams) {
          param['superQueryParams'] = encodeURI(this.superQueryParams)
          param['superQueryMatchType'] = this.superQueryMatchType
        }
        return filterObj(param);
      },
      handleFrozen: function (id, status, username) {
        let that = this;
        //TODO 后台校验管理员角色
        if ('admin' == username) {
          that.$message.warning('管理员账号不允许此操作！');
          return;
        }
        frozenBatch({ids: id, status: status}).then((res) => {
          if (res.success) {
            that.$message.success(res.message);
            that.loadData();
          } else {
            that.$message.warning(res.message);
          }
        });
      },
      handleaAtivateMail: function (merchantNo) {
        let that = this;
        activateMail({merchantNo: merchantNo}).then((res) => {
          if (res.success) {
            that.$message.success(res.message);
            that.loadData();
          } else {
            that.$message.warning(res.message);
          }
        });
      },
      handleChangePassword(username) {
        this.$refs.passwordmodal.show(username);
      },
      passwordModalOk() {
        //TODO 密码修改完成 不需要刷新页面，可以把datasource中的数据更新一下
      }
    }

  }
</script>