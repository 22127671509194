<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">

          <a-col :md="6" :sm="16">
            <a-form-item label="创建时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-range-picker
                v-model="queryParam.createDate"
                format="YYYY-MM-DD"
                :placeholder="['Begin Date', 'End Date']"
                @change="onDateChange"
                @ok="onDateOk"
              />
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('app.trade.merchantOrderNo')">
              <a-input  v-model="queryParam.merchantOrderNo"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item label="交易号">
              <a-input v-model="queryParam.tradeNo"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item label="商户号">
              <a-input v-model="queryParam.merchantNo"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item label="状态">
              <a-select placeholder="状态" v-model="queryParam.status">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="pending">待付款</a-select-option>
                <a-select-option value="complete">完成</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <!--<template v-if="toggleSearchStatus">

          </template>-->

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置查询</a-button>

              <!--<a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? '收取' : '展开' }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
              </a>-->
            </span>
          </a-col>

        </a-row>
      </a-form>
    </div>

    <!-- 操作按钮区域 -->
    <div class="table-operator" style="border-top: 5px">
      <a-button type="primary" icon="download" :loading="downloadLoading" @click="handleExportXls('交易记录')">导出</a-button>
    </div>

    <!-- table区域-begin -->
    <a-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="ipagination"
      :loading="loading"
      @change="handleTableChange">

      !-- 状态渲染模板 -->
      <template slot="status" slot-scope="status">
        <a-tag v-if="status=='complete'" color="green">success</a-tag>
        <a-tag v-if="status=='pending'" color="orange">pending</a-tag>
      </template>

      <!--<template #footer>Footer</template>-->

      <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
        <div  style="margin-bottom: 5px">
          <a-badge  style="vertical-align: middle;"/><span style="vertical-align: middle;">PSP 交易信息: {{ record.pspTradeNo }}</span>
        </div>
        <div v-if="record.status == 'complete' " style="margin-bottom: 5px">
          <a-badge  style="vertical-align: middle;"/><span style="vertical-align: middle;">交易完成时间:  {{ record.payDate }}</span>
        </div>
      </div>

    </a-table>
  </a-card>
</template>

<script>
  import Vue from 'vue'
  import {filterObj} from '@/utils/util';
  import {ListMixin} from '@/mixins/ListMixin'
  import Ellipsis from '@/components/Ellipsis'
  import {ACCESS_TOKEN } from "@/store/mutation-types"

  export default {
    name: "TradeList",
    mixins:[ListMixin],
    components: {
      Ellipsis,
    },
    data () {
      return {
        // 查询条件
        queryParam: {
          createDate:[],
          merchantOrderNo:'',
          tradeNo:'',
          status:'',
          merchantNo: ''
        },
        // 表头
        columns: [
          {
            title: '商户名称',
            align:"left",
            dataIndex: 'merchantName',
            scopedSlots: { customRender: 'merchantName' },
          },
          {
            title: '商户号',
            align:"center",
            dataIndex: 'merchantNo',
            scopedSlots: { customRender: 'merchantNo' },
          },
          {
            title: '商户订单号',
            align:"center",
            dataIndex: 'merchantOrderNo',
            scopedSlots: { customRender: 'merchantOrderNo' },
          },
          {
            title: '会员信息',
            dataIndex: 'custemInfo',
            align:"center",
          },
          {
            title: '交易号',
            dataIndex: 'tradeNo',
            align:"center",
          },
          {
            title: '币种',
            dataIndex: 'currency',
            align:"center",
          },
          {
            title: '金额',
            dataIndex: 'amount',
            align:"center",
          },
          {
            title: '状态',
            dataIndex: 'status',
            align:"center",
            scopedSlots: { customRender: 'status' },
          },
          {
            title: '创建时间',
            dataIndex: 'createDate',
            align:"center",
          }
        ],
        labelCol: {
          xs: { span: 1 },
          sm: { span: 2 },
        },
        wrapperCol: {
          xs: { span: 10 },
          sm: { span: 16 },
        },
        url: {
          list: "/sys/trade/tradeList"
        },
      }
    },
    methods: {
      getQueryParams(){
        var param = Object.assign({}, this.queryParam,this.isorter);
        param.token = Vue.ls.get(ACCESS_TOKEN);
        param.pageNo = this.ipagination.current;
        param.pageSize = this.ipagination.pageSize;
        delete param.createDate; // 时间参数不传递后台
        if (this.superQueryParams) {
          param['superQueryParams'] = encodeURI(this.superQueryParams)
          param['superQueryMatchType'] = this.superQueryMatchType
        }
        return filterObj(param);
      },

      // 重置
      searchReset(){
        var that = this;
        that.queryParam = {}; //清空查询区域参数
        that.loadData(this.ipagination.current);
      },
      onDateChange: function (value, dateString) {
        console.log(dateString[0],dateString[1]);
        this.queryParam.createTimeBegin=dateString[0] + ' 00:00:00';
        this.queryParam.createTimeEnd=dateString[1] + ' 23:59:59';
      },
      onDateOk(value) {
        console.log(value);
      },
    }
  }
</script>