<template>
  <a-card :bordered="false">

    <!-- table区域-begin -->
    <a-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="ipagination"
      :loading="loading"
      @change="handleTableChange">

      !-- 状态渲染模板 -->
      <template slot="status" slot-scope="status">
        <a-tag v-if="status=='success'" color="green">成功</a-tag>
        <a-tag v-if="status=='pending'" color="orange">待处理</a-tag>
        <a-tag v-if="status=='processing'" color="orange">处理中</a-tag>
        <a-tag v-if="status=='error'" color="red">异常</a-tag>
        <a-tag v-if="status=='fail'" color="red">失败</a-tag>
      </template>

      <span slot="action" slot-scope="text, record">
        <a-popconfirm title="确定提币私链成功吗?" @confirm="() => handleaConfirmSuccess(record.id)">
          <a>私链成功</a>
        </a-popconfirm>
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link">
            更多 <a-icon type="down"/>
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <!--<a-popconfirm title="确定提币公链成功吗?" @confirm="() => handleaConfirmSuccess(record.id)">
                <a>公链成功</a>
              </a-popconfirm>-->
              <a @click="handleEdit(record)" >公链成功</a>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm title="确定提币失败吗?" @confirm="() => handleaConfirmFail(record.id)">
                <a>提币失败</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <agentpay-h-b-p-s-modal ref="modalForm" @ok="modalFormOk"></agentpay-h-b-p-s-modal>
  </a-card>
</template>

<script>
  import Vue from 'vue'
  import {filterObj} from '@/utils/util';
  import {ListMixin} from '@/mixins/ListMixin'
  import Ellipsis from '@/components/Ellipsis'
  import {ACCESS_TOKEN } from "@/store/mutation-types"
  import {confirmAgent} from '@/api/api'
  import AgentpayHBPSModal from './modules/AgentpayHBPSModal'

  export default {
    name: "AgentpayProcess",
    mixins:[ListMixin],
    components: {
      Ellipsis,
      AgentpayHBPSModal
    },
    data () {
      return {
        // 查询条件
        queryParam: {
          createDate:[],
          toAddress:''
        },
        // 表头
        columns: [
          {
            title: '商户号',
            dataIndex: 'merchantNo',
            align:"left",
          },
          {
            title: '转入钱包',
            dataIndex: 'toAddress',
            align:"left",
          },
          {
            title: '金额',
            dataIndex: 'amount',
            align:"center",
          },
          {
            title: '币种',
            dataIndex: 'currency',
            align:"center",
          },
          {
            title: '状态',
            dataIndex: 'status',
            align:"center",
            scopedSlots: { customRender: 'status' },
          },
          {
            title: '备注',
            dataIndex: 'remark',
            align:"center",
          },
          {
            title: '提交时间',
            dataIndex: 'createDate',
            align:"center",
          },
          {
            title: '处理',
            scopedSlots: {customRender: 'action'},
            align: "center",
            width: 170
          }
        ],
        labelCol: {
          xs: { span: 1 },
          sm: { span: 2 },
        },
        wrapperCol: {
          xs: { span: 10 },
          sm: { span: 16 },
        },
        url: {
          list: "/sys/agentpay/agentpayConfirm",
        },
      }
    },
    methods: {
      getQueryParams(){
        var param = Object.assign({}, this.queryParam,this.isorter);
        param.token = Vue.ls.get(ACCESS_TOKEN);
        param.pageNo = this.ipagination.current;
        param.currency = "HUOBI_ERC20_USDT";
        param.pageSize = this.ipagination.pageSize;
        delete param.createDate; // 时间参数不传递后台
        if (this.superQueryParams) {
          param['superQueryParams'] = encodeURI(this.superQueryParams)
          param['superQueryMatchType'] = this.superQueryMatchType
        }
        return filterObj(param);
      },

      // 重置
      searchReset(){
        var that = this;
        that.queryParam = {}; //清空查询区域参数
        that.loadData(this.ipagination.current);
      },
      onDateChange: function (value, dateString) {
        console.log(dateString[0],dateString[1]);
        this.queryParam.createTimeBegin=dateString[0] + ' 00:00:00';
        this.queryParam.createTimeEnd=dateString[1] + ' 23:59:59';
      },
      onDateOk(value) {
        console.log(value);
      },
      handleaConfirmSuccess: function (id) {
        let that = this;
        confirmAgent({id: id,state:'success',type:'private'}).then((res) => {
          if (res.success) {
            that.$message.success(res.result.message);
            that.loadData();
          } else {
            that.$message.warning(res.result.message);
          }
        });
      },
      handleaConfirmFail: function (id) {
        let that = this;
        confirmAgent({id: id,state:'fail'}).then((res) => {
          if (res.success) {
            that.$message.success(res.message);
            that.loadData();
          } else {
            that.$message.warning(res.message);
          }
        });
      },
    }
  }
</script>