<template>
  <a-card :bordered="false">

    <!-- table区域-begin -->
    <a-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="ipagination"
      :loading="loading"
      @change="handleTableChange">

      !-- 状态渲染模板 -->
      <template slot="status" slot-scope="status">
        <a-tag v-if="status=='success'" color="green">成功</a-tag>
        <a-tag v-if="status=='pending'" color="orange">待处理</a-tag>
        <a-tag v-if="status=='processing'" color="orange">处理中</a-tag>
        <a-tag v-if="status=='error'" color="red">异常</a-tag>
        <a-tag v-if="status=='fail'" color="red">失败</a-tag>
      </template>

      <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)" >转账</a>
      </span>
    </a-table>
    <agentpayprocess-modal ref="modalForm" @ok="modalFormOk"></agentpayprocess-modal>
  </a-card>
</template>

<script>
  import AgentpayprocessModal from './modules/AgentpayProcessModal'
  import Vue from 'vue'
  import {filterObj} from '@/utils/util';
  import {ListMixin} from '@/mixins/ListMixin'
  import Ellipsis from '@/components/Ellipsis'
  import {ACCESS_TOKEN } from "@/store/mutation-types"

  export default {
    name: "AgentpayProcess",
    mixins:[ListMixin],
    components: {
      Ellipsis,
      AgentpayprocessModal
    },
    data () {
      return {
        // 查询条件
        queryParam: {
          createDate:[],
          toAddress:''
        },
        // 表头
        columns: [
          {
            title: '商户号',
            dataIndex: 'merchantNo',
            align:"left",
          },
          {
            title: '转入钱包',
            dataIndex: 'toAddress',
            align:"left",
          },
          {
            title: '金额',
            dataIndex: 'amount',
            align:"center",
          },
          {
            title: '币种',
            dataIndex: 'currency',
            align:"center",
          },
          {
            title: '状态',
            dataIndex: 'status',
            align:"center",
            scopedSlots: { customRender: 'status' },
          },
          {
            title: '备注',
            dataIndex: 'remark',
            align:"center",
          },
          {
            title: '提交时间',
            dataIndex: 'createDate',
            align:"center",
          },
          {
            title: '处理',
            scopedSlots: {customRender: 'action'},
            align: "center",
            width: 170
          }
        ],
        labelCol: {
          xs: { span: 1 },
          sm: { span: 2 },
        },
        wrapperCol: {
          xs: { span: 10 },
          sm: { span: 16 },
        },
        url: {
          list: "/sys/agentpay/agentpayProcess",
        },
      }
    },
    methods: {
      getQueryParams(){
        var param = Object.assign({}, this.queryParam,this.isorter);
        param.token = Vue.ls.get(ACCESS_TOKEN);
        param.pageNo = this.ipagination.current;
        param.currency = "USDT";
        param.pageSize = this.ipagination.pageSize;
        delete param.createDate; // 时间参数不传递后台
        if (this.superQueryParams) {
          param['superQueryParams'] = encodeURI(this.superQueryParams)
          param['superQueryMatchType'] = this.superQueryMatchType
        }
        return filterObj(param);
      },

      // 重置
      searchReset(){
        var that = this;
        that.queryParam = {}; //清空查询区域参数
        that.loadData(this.ipagination.current);
      },
      onDateChange: function (value, dateString) {
        console.log(dateString[0],dateString[1]);
        this.queryParam.createTimeBegin=dateString[0] + ' 00:00:00';
        this.queryParam.createTimeEnd=dateString[1] + ' 23:59:59';
      },
      onDateOk(value) {
        console.log(value);
      },
    }
  }
</script>