<template>
  <a-card :bordered="false" :title="$t('app.service.serviceInfo')">
    <div class="account-settings-info-view">
      <a-row :gutter="16">
        <a-col :md="24" :lg="10">

          <a-form layout="vertical">
            <a-form-item
              label="Merchant name"
            >
              <a-input v-model="data.merchantName" readonly/>
            </a-form-item>
            <a-form-item
              label="Merchant number"
            >
              <a-input v-model="data.merchantNo" readonly/>
            </a-form-item>

            <a-form-item
              label="Email"
              :required="false"
            >
              <a-input v-model="data.email" readonly/>
            </a-form-item>
            <a-form-item
              label="Merchant key"
              :required="false"
            >
              <a-textarea rows="2" v-model="data.merchantKey" readonly/>
            </a-form-item>
            <a-form-item
              label="Payment fee rate"
              :required="false"
            >
              <a-input v-model="data.paymentFeeRate"/>
            </a-form-item>
            <a-form-item
              label="Withdrawal fee"
              :required="false"
            >
              <a-input v-model="data.withdrawalFee"/>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </div>
  </a-card>
</template>

<script>
  import { queryServiveInfo} from '@/api/api'
  import Vue from 'vue'
  import {ACCESS_TOKEN } from "@/store/mutation-types"

export default {

  data () {
    return {
      // cropper
      data:{}
    }
  },
  created() {
    this.initDate();
  },
  methods: {
    initDate(){
      var param = {token:Vue.ls.get(ACCESS_TOKEN)};
      queryServiveInfo(param).then((res)=>{
        if(res.success){
          this.data = res.result;
          this.data.paymentFeeRate = this.data.paymentFeeRate + "%";
          this.data.withdrawalFee = this.data.withdrawalFee + " USDT per withdrawal";
        }else{
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>

  .avatar-upload-wrapper {
    height: 200px;
    width: 100%;
  }

  .ant-upload-preview {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 180px;
    border-radius: 50%;
    box-shadow: 0 0 4px #ccc;

    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mask {
      opacity: 0;
      position: absolute;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
      transition: opacity 0.4s;

      &:hover {
        opacity: 1;
      }

      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }

    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
    }
  }
</style>
