<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">

          <a-col :md="6" :sm="16">
            <a-form-item label="提交时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-range-picker
                v-model="queryParam.createDate"
                format="YYYY-MM-DD"
                :placeholder="['begin Date', 'end Date']"
                @change="onDateChange"
                @ok="onDateOk"
              />
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item label="转入钱包">
              <a-input  v-model="queryParam.toAddress"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <!-- 操作按钮区域 -->
    <div class="table-operator" style="border-top: 5px">
      <a-button type="primary" icon="download" :loading="downloadLoading" @click="handleExportXls('提币记录')">导出</a-button>
    </div>

    <!-- table区域-begin -->
    <a-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="ipagination"
      :loading="loading"
      @change="handleTableChange">

      !-- 状态渲染模板 -->
      <template slot="status" slot-scope="status">
        <a-tag v-if="status=='success'" color="green">成功</a-tag>
        <a-tag v-if="status=='pending'" color="orange">待处理</a-tag>
        <a-tag v-if="status=='processing'" color="orange">处理中</a-tag>
        <a-tag v-if="status=='error'" color="red">异常</a-tag>
        <a-tag v-if="status=='fail'" color="red">失败</a-tag>
      </template>


      <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
        <div v-if="record.status == 'success'" style="margin-bottom: 5px">
          <a-badge status="success" style="vertical-align: middle;"/><span style="vertical-align: middle;">From - wallet Address:  {{ record.fromAddress }}</span>
        </div>
        <div v-if="record.status == 'success'" style="margin-bottom: 5px">
          <a-badge status="success" style="vertical-align: middle;"/><span style="vertical-align: middle;">success - Txn Hash: {{ record.tokenTradeHash }}</span>
        </div>
        <div v-if="record.status == 'success'" style="margin-bottom: 5px">
          <a-badge status="success" style="vertical-align: middle;"/><span style="vertical-align: middle;">trade Date: {{ record.tradeDate }}</span>
        </div>
        <div v-if="record.status == 'pending'" style="margin-bottom: 5px">
          <a-badge status="warning" style="vertical-align: middle;"/><span style="vertical-align: middle;">warning</span>
        </div>
        <div v-if="record.status == 'processing'" style="margin-bottom: 5px">
          <a-badge status="processing" style="vertical-align: middle;"/><span style="vertical-align: middle;">From - wallet Address: {{ record.fromAddress }}</span>
        </div>
        <div v-if="record.status == 'processing'" style="margin-bottom: 5px">
          <a-badge status="processing" style="vertical-align: middle;"/><span style="vertical-align: middle;">processing - Txn Hash: {{ record.tokenTradeHash }}</span>
        </div>
        <div v-if="record.status == 'error'" style="margin-bottom: 5px">
          <a-badge status="error" style="vertical-align: middle;"/><span style="vertical-align: middle;">error</span>
        </div>
        <div v-if="record.status == 'fail'" style="margin-bottom: 5px">
          <a-badge status="error" style="vertical-align: middle;"/><span style="vertical-align: middle;">fail</span>
        </div>
      </div>
    </a-table>
  </a-card>
</template>

<script>
  import Vue from 'vue'
  import {filterObj} from '@/utils/util';
  import {ListMixin} from '@/mixins/ListMixin'
  import Ellipsis from '@/components/Ellipsis'
  import {ACCESS_TOKEN } from "@/store/mutation-types"

  export default {
    name: "AgentpayList",
    mixins:[ListMixin],
    components: {
      Ellipsis,
    },
    data () {
      return {
        // 查询条件
        queryParam: {
          createDate:[],
          toAddress:''
        },
        // 表头
        columns: [
          {
            title: '商户名称',
            dataIndex: 'merchantName',
            align:"left",
          },
          {
            title: '商户号',
            dataIndex: 'merchantNo',
            align:"left",
          },
          {
            title: '转入钱包',
            dataIndex: 'toAddress',
            align:"left",
          },
          {
            title: '金额',
            dataIndex: 'amount',
            align:"center",
          },
          {
            title: '币种',
            dataIndex: 'currency',
            align:"center",
          },
          {
            title: '状态',
            dataIndex: 'status',
            align:"center",
            scopedSlots: { customRender: 'status' },
          },
          {
            title: '备注',
            dataIndex: 'remark',
            align:"center",
          },
          {
            title: '提交时间',
            dataIndex: 'createDate',
            align:"center",
          }
        ],
        labelCol: {
          xs: { span: 1 },
          sm: { span: 2 },
        },
        wrapperCol: {
          xs: { span: 10 },
          sm: { span: 16 },
        },
        url: {
          list: "/sys/agentpay/agentpayList",
        },
      }
    },
    methods: {
      getQueryParams(){
        var param = Object.assign({}, this.queryParam,this.isorter);
        param.token = Vue.ls.get(ACCESS_TOKEN);
        param.pageNo = this.ipagination.current;
        param.pageSize = this.ipagination.pageSize;
        delete param.createDate; // 时间参数不传递后台
        if (this.superQueryParams) {
          param['superQueryParams'] = encodeURI(this.superQueryParams)
          param['superQueryMatchType'] = this.superQueryMatchType
        }
        return filterObj(param);
      },

      // 重置
      searchReset(){
        var that = this;
        that.queryParam = {}; //清空查询区域参数
        that.loadData(this.ipagination.current);
      },
      onDateChange: function (value, dateString) {
        console.log(dateString[0],dateString[1]);
        this.queryParam.createTimeBegin=dateString[0] + ' 00:00:00';
        this.queryParam.createTimeEnd=dateString[1] + ' 23:59:59';
      },
      onDateOk(value) {
        console.log(value);
      },
    }
  }
</script>