<template>
    <div style="margin: 10px;">
      <a-row :gutter="24">
        <a-col :xl="16" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :loading="loading" title="今天最新动态" :bordered="false">
            <a-list>
              <a-list-item :key="index" v-for="(item, index) in tradeDate">
                <a-list-item-meta>
                  <div slot="title">
                    <span v-if="item.type  == 'agentpay'" style="color: green">Transfer out</span>&nbsp;&nbsp;
                    <span v-if="item.type  == 'payment'" style="color: red">Transfer in</span>&nbsp;&nbsp;
                    <span style="color: blue">{{item.orderNo}}</span>&nbsp;&nbsp;
                    <span style="color: red">{{item.amount}} </span>ERC-20 USDT&nbsp;&nbsp;
                  </div>
                  <div slot="description">{{ item.createDate }}</div>
                </a-list-item-meta>
              </a-list-item>
            </a-list>
          </a-card>
        </a-col>
      </a-row>
    </div>
</template>

<script>
  import {timeFix} from '@/utils/util'
  import Vue from 'vue'
  import {ACCESS_TOKEN } from "@/store/mutation-types"
  import {PageHeaderWrapper} from '@ant-design-vue/pro-layout'
  import Radar from '@/components/Charts/Radar'
  import {activity, projects, radar, teams} from '@/mock/services/manage'
  import { queryTradeList} from '@/api/api'


  const DataSet = require('@antv/data-set')

export default {
  name: 'Workplace',
  components: {
    PageHeaderWrapper,
    Radar
  },
  data () {
    return {
      timeFix: timeFix(),
      loading: true,
      radarLoading: true,
      balance: 0.00,
      tradeDate:[]
    }
  },
  created () {
  },
  mounted () {
    this.getProjects()
    this.getActivity()
    this.getTeams()
    this.initRadar()
    this.initTrade()
  },
  methods: {
    getProjects () {
      this.projects = projects().result.data
      this.loading = false

    },
    getActivity () {
      this.activities = activity().result

    },
    getTeams () {
      this.teams = teams().result

    },
    initRadar () {
      const dv = new DataSet.View().source(radar().result)
      dv.transform({
        type: 'fold',
        fields: ['个人', '团队', '部门'],
        key: 'user',
        value: 'score'
      })
      this.radarData = dv.rows
      this.radarLoading = false

    },
    initTrade(){
      var param = {token:Vue.ls.get(ACCESS_TOKEN)};
      queryTradeList(param).then((res)=>{
        if(res.success){
          this.tradeDate = res.result;
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./Workplace.less";

  .project-list {

    .card-title {
      font-size: 0;

      a {
        color: rgba(0, 0, 0, 0.85);
        margin-left: 12px;
        line-height: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: top;
        font-size: 14px;

        &:hover {
          color: #1890ff;
        }
      }
    }

    .card-description {
      color: rgba(0, 0, 0, 0.45);
      height: 44px;
      line-height: 22px;
      overflow: hidden;
    }

    .project-item {
      display: flex;
      margin-top: 8px;
      overflow: hidden;
      font-size: 12px;
      height: 20px;
      line-height: 20px;

      a {
        color: rgba(0, 0, 0, 0.45);
        display: inline-block;
        flex: 1 1 0;

        &:hover {
          color: #1890ff;
        }
      }

      .datetime {
        color: rgba(0, 0, 0, 0.25);
        flex: 0 0 auto;
        float: right;
      }
    }

    .ant-card-meta-description {
      color: rgba(0, 0, 0, 0.45);
      height: 44px;
      line-height: 22px;
      overflow: hidden;
    }
  }

  .item-group {
    padding: 20px 0 8px 24px;
    font-size: 0;

    a {
      color: rgba(0, 0, 0, 0.65);
      display: inline-block;
      font-size: 14px;
      margin-bottom: 13px;
      width: 25%;
    }
  }

  .members {
    a {
      display: block;
      margin: 12px 0;
      line-height: 24px;
      height: 24px;

      .member {
        font-size: 14px;
        color: rgba(0, 0, 0, .65);
        line-height: 24px;
        max-width: 100px;
        vertical-align: top;
        margin-left: 12px;
        transition: all 0.3s;
        display: inline-block;
      }

      &:hover {
        span {
          color: #1890ff;
        }
      }
    }
  }

  .mobile {

    .project-list {

      .project-card-grid {
        width: 100%;
      }
    }

    .more-info {
      border: 0;
      padding-top: 16px;
      margin: 16px 0 16px;
    }

    .headerContent .title .welcome-text {
      display: none;
    }
  }

</style>
