<template>
  <a-card :bordered="false">

    <!-- 操作按钮区域 -->
    <div class="table-operator" style="border-top: 5px">
      <a-button @click="handleAdd" type="primary" icon="plus">添加</a-button>
    </div>


    <!-- table区域-begin -->
    <div>
      <a-table
        ref="table"
        bordered
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        @change="handleTableChange">

        <template slot="status" slot-scope="status">
          <a-tag v-if="status=='normal'" color="green">正常</a-tag>
          <a-tag v-if="status=='unavailable'" color="red">禁用</a-tag>
        </template>

        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)" >编辑</a>
        </span>


      </a-table>
    </div>
    <!-- table区域-end -->
    <channel-modal ref="modalForm" @ok="modalFormOk"></channel-modal>

  </a-card>
</template>

<script>
  import ChannelModal from './modules/ChannelModal'
  import {getFileAccessHttpUrl} from '@/api/manage';
  import {ListMixin} from '@/mixins/ListMixin'
  import {filterObj} from '@/utils/util';
  import Vue from 'vue'
  import {ACCESS_TOKEN } from "@/store/mutation-types"

  export default {
    name: "ChannelList",
    mixins: [ListMixin],
    components: {
      ChannelModal
    },
    data() {
      return {
        description: '通道管理',
        queryParam: {},
        columns: [
          {
            title: '收单商户号',
            align:"left",
            dataIndex: 'channelMerchantId',
          },
          {
            title: '通道名称',
            dataIndex: 'channelName',
            align:"center",
          },
          {
            title: '通道币种',
            dataIndex: 'currency',
            align:"center",
          },
          {
            title: '服务依赖编码',
            dataIndex: 'serviceModular',
            align:"center",
          },
          {
            title: '状态',
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' },
            align:"center",
          },
          {
            title: '操作',
            scopedSlots: {customRender: 'action'},
            align: "center",
            width: 170
          }
        ],
        url: {
          list: "/sys/channel/channelList",
        },
      }
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      }
    },
    methods: {
      getQueryParams(){
        var param = Object.assign({}, this.queryParam,this.isorter);
        param.token = Vue.ls.get(ACCESS_TOKEN);
        param.pageNo = this.ipagination.current;
        param.pageSize = this.ipagination.pageSize;
        if (this.superQueryParams) {
          param['superQueryParams'] = encodeURI(this.superQueryParams)
          param['superQueryMatchType'] = this.superQueryMatchType
        }
        return filterObj(param);
      },
      handleFrozen: function (id, status, username) {
        let that = this;
        //TODO 后台校验管理员角色
        if ('admin' == username) {
          that.$message.warning('管理员账号不允许此操作！');
          return;
        }

      },
      handleChangePassword(username) {
        this.$refs.passwordmodal.show(username);
      },
      passwordModalOk() {
        //TODO 密码修改完成 不需要刷新页面，可以把datasource中的数据更新一下
      }
    }

  }
</script>