<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">

          <a-col :md="6" :sm="16">
            <a-form-item label="创建时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-range-picker
                v-model="queryParam.createDate"
                format="YYYY-MM-DD"
                :placeholder="['begin Date', 'end Date']"
                @change="onDateChange"
                @ok="onDateOk"
              />
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item label="商户号">
              <a-input v-model="queryParam.merchantNo"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <!-- table区域-begin -->
    <a-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="ipagination"
      :loading="loading"
      @change="handleTableChange">

      <template slot="tradeType" slot-scope="tradeType">
        <a-tag v-if="tradeType=='payment'" color="green">支付</a-tag>
        <a-tag v-if="tradeType=='agenypay'" color="orange">代付</a-tag>
        <a-tag v-if="tradeType=='fee'" color="orange">手续费</a-tag>
      </template>

      <span slot="settleAmount" slot-scope="text, record">
        <span v-if="record.tradeType == 'payment'" style="color: green">+{{record.settleAmount}}</span>
        <span v-if="record.tradeType == 'agenypay'" style="color: red">-{{record.settleAmount}}</span>
        <span v-if="record.tradeType == 'fee'" style="color: red">-{{record.settleAmount}}</span>
      </span>

    </a-table>
  </a-card>
</template>

<script>
  import Vue from 'vue'
  import moment from 'moment'
  import {filterObj} from '@/utils/util';
  import {ListMixin} from '@/mixins/ListMixin'
  import Ellipsis from '@/components/Ellipsis'
  import {ACCESS_TOKEN } from "@/store/mutation-types"

  export default {
    name: "TradeList",
    mixins:[ListMixin],
    components: {
      Ellipsis,
    },
    data () {
      return {
        moment,
        // 查询条件
        queryParam: {
          createDate:[]
        },
        // 表头
        columns: [
          {
            title: '商户号',
            align:"left",
            dataIndex: 'merchantNo',
            scopedSlots: { customRender: 'merchantNo' },
          },
          {
            title: '流水号',
            align:"left",
            dataIndex: 'serialNo',
            scopedSlots: { customRender: 'serialNo' },
          },
          {
            title: '币种',
            dataIndex: 'currency',
            align:"center",
          },
          {
            title: '类型',
            dataIndex: 'tradeType',
            scopedSlots: { customRender: 'tradeType' },
            align:"center",
          },
          {
            title: '金额',
            dataIndex: 'settleAmount',
            scopedSlots: {customRender: "settleAmount"},
            align:"center",
          },
          {
            title: '本期金额',
            dataIndex: 'logBalance',
            align:"center",
          },
          {
            title: '创建时间',
            dataIndex: 'createDate',
            align:"center",
          }
        ],
        labelCol: {
          xs: { span: 1 },
          sm: { span: 2 },
        },
        wrapperCol: {
          xs: { span: 10 },
          sm: { span: 16 },
        },
        url: {
          list: "/sys/trade/settleDetails",
        },
      }
    },
    methods: {
      getQueryParams(){
        var param = Object.assign({}, this.queryParam,this.isorter);
        param.token = Vue.ls.get(ACCESS_TOKEN);
        param.pageNo = this.ipagination.current;
        param.pageSize = this.ipagination.pageSize;
        delete param.createDate; // 时间参数不传递后台
        if (this.superQueryParams) {
          param['superQueryParams'] = encodeURI(this.superQueryParams)
          param['superQueryMatchType'] = this.superQueryMatchType
        }
        return filterObj(param);
      },

      // 重置
      searchReset(){
        var that = this;
        that.queryParam = {}; //清空查询区域参数
        that.loadData(this.ipagination.current);
      },
      onDateChange: function (value, dateString) {
        console.log(dateString[0],dateString[1]);
        this.queryParam.createTimeBegin=dateString[0] + ' 00:00:00';
        this.queryParam.createTimeEnd=dateString[1] + ' 23:59:59';
      },
      onDateOk(value) {
        console.log(value);
      },
    }
  }
</script>