var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":6,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"仓库名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'repository.name',
            {rules: [{ required: true, message: '请输入仓库名称', whitespace: true}]}
          ]),expression:"[\n            'repository.name',\n            {rules: [{ required: true, message: '请输入仓库名称', whitespace: true}]}\n          ]"}],attrs:{"placeholder":"请输入仓库名称"}})],1)],1),_c('a-col',{attrs:{"xl":{span: 7, offset: 1},"lg":{span: 8},"md":{span: 12},"sm":24}},[_c('a-form-item',{attrs:{"label":"仓库域名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'repository.domain',
            {rules: [{ required: true, message: '请输入仓库域名', whitespace: true}, {validator: _vm.validate}]}
          ]),expression:"[\n            'repository.domain',\n            {rules: [{ required: true, message: '请输入仓库域名', whitespace: true}, {validator: validate}]}\n          ]"}],attrs:{"addonBefore":"http://","addonAfter":".com","placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"xl":{span: 9, offset: 1},"lg":{span: 10},"md":{span: 24},"sm":24}},[_c('a-form-item',{attrs:{"label":"仓库管理员"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'repository.manager', {rules: [{ required: true, message: '请选择管理员'}]} ]),expression:"[ 'repository.manager', {rules: [{ required: true, message: '请选择管理员'}]} ]"}],attrs:{"placeholder":"请选择管理员"}},[_c('a-select-option',{attrs:{"value":"王同学"}},[_vm._v("王同学")]),_c('a-select-option',{attrs:{"value":"李同学"}},[_vm._v("李同学")]),_c('a-select-option',{attrs:{"value":"黄同学"}},[_vm._v("黄同学")])],1)],1)],1)],1),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":6,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"审批人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'repository.auditor', {rules: [{ required: true, message: '请选择审批员'}]} ]),expression:"[ 'repository.auditor', {rules: [{ required: true, message: '请选择审批员'}]} ]"}],attrs:{"placeholder":"请选择审批员"}},[_c('a-select-option',{attrs:{"value":"王晓丽"}},[_vm._v("王晓丽")]),_c('a-select-option',{attrs:{"value":"李军"}},[_vm._v("李军")])],1)],1)],1),_c('a-col',{attrs:{"xl":{span: 7, offset: 1},"lg":{span: 8},"md":{span: 12},"sm":24}},[_c('a-form-item',{attrs:{"label":"生效日期"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'repository.effectiveDate',
            {rules: [{ required: true, message: '请选择生效日期'}]}
          ]),expression:"[\n            'repository.effectiveDate',\n            {rules: [{ required: true, message: '请选择生效日期'}]}\n          ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"xl":{span: 9, offset: 1},"lg":{span: 10},"md":{span: 24},"sm":24}},[_c('a-form-item',{attrs:{"label":"仓库类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'repository.type',
            {rules: [{ required: true, message: '请选择仓库类型'}]}
          ]),expression:"[\n            'repository.type',\n            {rules: [{ required: true, message: '请选择仓库类型'}]}\n          ]"}],attrs:{"placeholder":"请选择仓库类型"}},[_c('a-select-option',{attrs:{"value":"公开"}},[_vm._v("公开")]),_c('a-select-option',{attrs:{"value":"私密"}},[_vm._v("私密")])],1)],1)],1)],1),(_vm.showSubmit)?_c('a-form-item',[_c('a-button',{attrs:{"htmlType":"submit"}},[_vm._v("Submit")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }