<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">

          <a-col :md="6" :sm="16">
            <a-form-item label="创建时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-range-picker
                v-model="queryParam.createDate"
                format="YYYY-MM-DD"
                :placeholder="['Begin Date', 'End Date']"
                @change="onDateChange"
                @ok="onDateOk"
              />
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item label="UID">
              <a-input v-model="queryParam.uid"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item label="匹配交易号">
              <a-input v-model="queryParam.tradeNo"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置查询</a-button>
            </span>
          </a-col>

        </a-row>
      </a-form>
    </div>

    <!-- table区域-begin -->
    <div>
      <a-table
        ref="table"
        bordered
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        @change="handleTableChange">

        <template slot="isOrderMatch" slot-scope="isOrderMatch">
          <a-tag v-if="isOrderMatch=='yes'" color="green">匹配成功</a-tag>
          <a-tag v-if="isOrderMatch=='no'" color="red">无匹配</a-tag>
        </template>

      </a-table>
    </div>
    <!-- table区域-end -->
    <sub-user-modal ref="modalForm" @ok="modalFormOk"></sub-user-modal>

  </a-card>
</template>

<script>
  import SubUserModal from './modules/SubUserModal'
  import {getFileAccessHttpUrl} from '@/api/manage';
  import {ListMixin} from '@/mixins/ListMixin'
  import {filterObj} from '@/utils/util';
  import Vue from 'vue'
  import {ACCESS_TOKEN } from "@/store/mutation-types"

  export default {
    name: "DepositRecordList",
    mixins: [ListMixin],
    components: {
      SubUserModal
    },
    data() {
      return {
        description: '子账户充币记录',
        queryParam: {

        },
        columns: [
          {
            title: '子账户UID',
            dataIndex: 'subUId',
            align:"center",
          },
          {
            title: '充币ID',
            dataIndex: 'depositId',
            align:"center",
          },
          {
            title: '金额',
            dataIndex: 'amount',
            align:"center",
          },
          {
            title: '充币地址',
            dataIndex: 'address',
            align:"center",
          },
          {
            title: '匹配交易号',
            dataIndex: 'tradeNo',
            align:"center",
          },
          {
            title: '是否匹配',
            dataIndex: 'isOrderMatch',
            scopedSlots: { customRender: 'isOrderMatch' },
            align:"center",
          },
          {
            title: '状态',
            dataIndex: 'state',
            align:"center",
          },
          {
            title: '同步时间',
            dataIndex: 'createDate',
            align:"center",
          }
        ],
        labelCol: {
          xs: { span: 1 },
          sm: { span: 2 },
        },
        wrapperCol: {
          xs: { span: 10 },
          sm: { span: 16 },
        },
        url: {
          list: "/sys/huobi/getHuobiDepositRecord",
        },
      }
    },
    computed: {
    },
    methods: {
      getQueryParams(){
        var param = Object.assign({}, this.queryParam,this.isorter);
        param.token = Vue.ls.get(ACCESS_TOKEN);
        param.pageNo = this.ipagination.current;
        param.pageSize = this.ipagination.pageSize;
        if (this.superQueryParams) {
          param['superQueryParams'] = encodeURI(this.superQueryParams)
          param['superQueryMatchType'] = this.superQueryMatchType
        }
        return filterObj(param);
      },

      onDateChange: function (value, dateString) {
        console.log(dateString[0],dateString[1]);
        this.queryParam.createTimeBegin=dateString[0] + ' 00:00:00';
        this.queryParam.createTimeEnd=dateString[1] + ' 23:59:59';
      },
      onDateOk(value) {
        console.log(value);
      },
    }

  }
</script>