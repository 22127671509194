<template>
  <a-card :bordered="false">

    <!-- 操作按钮区域 -->
    <div class="table-operator" style="border-top: 5px">
      <a-button @click="handleAdd" type="primary" icon="plus">添加</a-button>
    </div>


    <!-- table区域-begin -->
    <div>
      <a-table
        ref="table"
        bordered
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        @change="handleTableChange">

        <template slot="status" slot-scope="status">
          <a-tag v-if="status=='normal'" color="green">正常</a-tag>
          <a-tag v-if="status=='unavailable'" color="red">禁用</a-tag>
        </template>

        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)" >编辑</a>
        </span>


      </a-table>
    </div>
    <!-- table区域-end -->
    <parent-account-modal ref="modalForm" @ok="modalFormOk"></parent-account-modal>

  </a-card>
</template>

<script>
  import ParentAccountModal from './modules/ParentAccountModal'
  import {getFileAccessHttpUrl} from '@/api/manage';
  import {ListMixin} from '@/mixins/ListMixin'
  import {filterObj} from '@/utils/util';
  import Vue from 'vue'
  import {ACCESS_TOKEN } from "@/store/mutation-types"

  export default {
    name: "ParentAccount",
    mixins: [ListMixin],
    components: {
      ParentAccountModal
    },
    data() {
      return {
        description: '母账户管理',
        queryParam: {},
        columns: [
          {
            title: '火币AccountId',
            dataIndex: 'accountId',
            align:"center",
          },
          {
            title: '名称',
            dataIndex: 'userName',
            align:"center",
          },
          {
            title: 'AccessKey',
            dataIndex: 'accessKey',
            align:"center",
          },
          {
            title: 'SecretKey',
            dataIndex: 'secretKey',
            align:"center",
          },
          {
            title: '子账户数量',
            dataIndex: 'subUserNum',
            align:"center",
          },
          {
            title: '状态',
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' },
            align:"center",
          },
          {
            title: '操作',
            scopedSlots: {customRender: 'action'},
            align: "center",
            width: 170
          }
        ],
        url: {
          list: "/sys/huobi/parentAccountList",
        },
      }
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      }
    },
    methods: {
      getQueryParams(){
        var param = Object.assign({}, this.queryParam,this.isorter);
        param.token = Vue.ls.get(ACCESS_TOKEN);
        param.pageNo = this.ipagination.current;
        param.pageSize = this.ipagination.pageSize;
        if (this.superQueryParams) {
          param['superQueryParams'] = encodeURI(this.superQueryParams)
          param['superQueryMatchType'] = this.superQueryMatchType
        }
        return filterObj(param);
      },

      passwordModalOk() {
        //TODO 密码修改完成 不需要刷新页面，可以把datasource中的数据更新一下
      }
    }

  }
</script>