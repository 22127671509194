<template>
  <div>
    <a-form style="max-width: 500px; margin: 40px auto 0;" :form="form" @submit="handleSubmit">
      <a-form-item
        label="Account"
        :labelCol="{span: 5}"
        :wrapperCol="{span: 19}"
      >
        <a-select value="1" placeholder="ERC20 Token USDT">
          <a-select-option value="1" seleced>ERC20 Token USDT(Balance {{balance}} USDT)</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="To Address"
        :labelCol="{span: 5}"
        :wrapperCol="{span: 19}"
      >
        <a-input placeholder="Please enter the receiving wallet address" v-decorator="['toAddress',{ rules: [{ required: true, message: 'Please enter the receiving token address!' }]}]"></a-input>
      </a-form-item>
      <a-form-item
        label="Remarks"
        :labelCol="{span: 5}"
        :wrapperCol="{span: 19}"
      >
        <a-input placeholder="remarks" v-decorator="['remark']" />
      </a-form-item>
      <a-form-item
        label="Amount"
        :labelCol="{span: 5}"
        :wrapperCol="{span: 19}"
      >
        <a-input placeholder="Amount" v-decorator="['amount',{ rules: [{ required: true, message: 'amount' }]}]" />
      </a-form-item>
      <a-form-item :wrapperCol="{span: 19, offset: 5}">
        <a-button type="primary" html-type="submit">Next step</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
  import { queryMerchantBalance} from '@/api/api'
  import Vue from 'vue'
  import {ACCESS_TOKEN } from "@/store/mutation-types"

  export default {
    name: "Step1",
    data() {
      return {
        form: this.$form.createForm(this),
        balance: 0.00,
        agentpayFee:0.00,
      };
    },
    created () {
      this.initBalance();
    },
    methods: {
      handleSubmit (e) {
        e.preventDefault();
        this.form.validateFields((err, values) => {
          if (!err) {
            if(this.balance - values.amount - this.agentpayFee >= 0){
              sessionStorage.setItem('submitAgentPayParams', JSON.stringify(values));
              this.$emit('nextStep')
            }else{
              this.$message.warning('Insufficient balance');
            }
          }
        });
      },
      initBalance(){
        var param = {token:Vue.ls.get(ACCESS_TOKEN),currency:'USDT'};
        queryMerchantBalance(param).then((res)=>{
          if(res.success){
            this.balance = res.result.balance;
            this.agentpayFee = res.result.agentpayFee;
          }else{
            this.balance = 0.00
          }
        });
      }
    }
  }
</script>

<style scoped>

</style>