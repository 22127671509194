<template>
  <a-card :bordered="false">

    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">

          <a-col :md="6" :sm="8">
            <a-form-item label="账户名称">
              <a-input v-model="queryParam.accountName"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item label="账户号">
              <a-input v-model="queryParam.accountNo"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <!-- table区域-begin -->
    <div>
      <a-table
        ref="table"
        bordered
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        @change="handleTableChange">


        <template slot="status" slot-scope="status">
          <a-tag v-if="status=='1'" color="green">正常</a-tag>
          <a-tag v-if="status=='0'" color="red">禁用</a-tag>
        </template>
        <template slot="accountBalance" slot-scope="accountBalance">{{accountBalance /100}}
        </template>
      </a-table>
    </div>
  </a-card>
</template>

<script>
  import {getFileAccessHttpUrl} from '@/api/manage';
  import {ListMixin} from '@/mixins/ListMixin'
  import {filterObj} from '@/utils/util';
  import Vue from 'vue'
  import {ACCESS_TOKEN } from "@/store/mutation-types"

  export default {
    name: "AccountList",
    mixins: [ListMixin],
    components: {
    },
    data() {
      return {
        description: '账户管理',
        queryParam: {
          accountName:'',
          accountNo: ''
        },
        columns: [
          {
            title: '账户名称',
            align:"left",
            dataIndex: 'accountName',
          },
          {
            title: '账户号',
            dataIndex: 'accountNo',
            align:"center",
          },
          {
            title: '账户类型',
            dataIndex: 'accountType',
            scopedSlots: { customRender: 'accountType' },
            align:"center",
          },
          {
            title: '账户余额',
            dataIndex: 'accountBalance',
            scopedSlots: { customRender: 'accountBalance' },
            align:"center",
          },
          {
            title: '状态',
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' },
            align:"center",
          },
          {
            title: '币种',
            dataIndex: 'currency',
            align:"center",
          },
          {
            title: '创建时间',
            dataIndex: 'createDate',
            align:"center",
          }
        ],
        url: {
          list: "/sys/account/accountList",
        },
      }
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      }
    },
    methods: {
      getQueryParams(){
        var param = Object.assign({}, this.queryParam,this.isorter);
        param.token = Vue.ls.get(ACCESS_TOKEN);
        param.pageNo = this.ipagination.current;
        param.pageSize = this.ipagination.pageSize;
        if (this.superQueryParams) {
          param['superQueryParams'] = encodeURI(this.superQueryParams)
          param['superQueryMatchType'] = this.superQueryMatchType
        }
        return filterObj(param);
      }
    }

  }
</script>