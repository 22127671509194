<template>
  <a-card :bordered="false">

    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">

          <a-col :md="3" :sm="6">
            <a-form-item label="USDT余额">
              <a-input type="number" v-model="queryParam.beginAmount"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="4">
            <a-form-item label="">
              <a-input type="number" v-model="queryParam.endAmount"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item label="钱包地址">
              <a-input v-model="queryParam.address"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <!-- table区域-begin -->
    <div>
      <a-table
        ref="table"
        bordered
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        @change="handleTableChange">

        <template slot="useStatus" slot-scope="useStatus">
          <a-tag v-if="useStatus=='available'" color="green">可用</a-tag>
          <a-tag v-if="useStatus=='lock'" color="red">锁定</a-tag>
        </template>

        <template slot="usdtBalance" slot-scope="usdtBalance">{{usdtBalance /100}}
        </template>

        <template slot="ethBalance" slot-scope="ethBalance">{{ethBalance /100}}
        </template>

        <span slot="action" slot-scope="text, record">
          <a v-if="record.useStatus=='available'" @click="handleEdit(record)" >汇集</a>
        </span>

      </a-table>
    </div>

    <wallet-modal ref="modalForm" @ok="modalFormOk"></wallet-modal>

  </a-card>
</template>

<script>
  import WalletModal from './modules/WalletModal'
  import {getFileAccessHttpUrl} from '@/api/manage';
  import {ListMixin} from '@/mixins/ListMixin'
  import {filterObj} from '@/utils/util';
  import Vue from 'vue'
  import {ACCESS_TOKEN } from "@/store/mutation-types"

  export default {
    name: "WalletList",
    mixins: [ListMixin],
    components: {
      WalletModal
    },
    data() {
      return {
        description: '钱包管理',
        queryParam: {
          address:'',
          beginAmount: null,
          endAmount: null
        },
        columns: [
          {
            title: '钱包地址',
            align:"left",
            dataIndex: 'tokenAddress',
          },
          {
            title: 'USDT余额',
            dataIndex: 'usdtBalance',
            scopedSlots: { customRender: 'usdtBalance' },
            align:"center",
          },
          {
            title: 'ETH余额',
            dataIndex: 'ethBalance',
            scopedSlots: { customRender: 'ethBalance' },
            align:"center",
          },
          {
            title: '使用次数',
            dataIndex: 'useNum',
            align:"center",
          },
          {
            title: '使用状态',
            dataIndex: 'useStatus',
            scopedSlots: { customRender: 'useStatus' },
            align:"center",
          },
          {
            title: '锁定时间',
            dataIndex: 'unlockTime',
            align:"center",
          },
          {
            title: '创建时间',
            dataIndex: 'createDate',
            align:"center",
          },
          {
            title: '操作',
            scopedSlots: {customRender: 'action'},
            align: "center",
            width: 170
          }
        ],
        url: {
          list: "/sys/wallet/walletList",
        },
      }
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      }
    },
    methods: {
      getQueryParams(){
        var param = Object.assign({}, this.queryParam,this.isorter);
        param.token = Vue.ls.get(ACCESS_TOKEN);
        param.pageNo = this.ipagination.current;
        param.pageSize = this.ipagination.pageSize;
        if (this.superQueryParams) {
          param['superQueryParams'] = encodeURI(this.superQueryParams)
          param['superQueryMatchType'] = this.superQueryMatchType
        }
        return filterObj(param);
      }
    }

  }
</script>