<template>
  <a-drawer
    :title="title"
    :maskClosable="false"
    :width="drawerWidth"
    placement="right"
    :closable="true"
    @close="handleCancel"
    :visible="visible"
    style="height: 100%;overflow: auto;padding-bottom: 53px;">

    <template slot="title">
      <div style="width: 100%;">
        <span>{{ title }}</span>
        <span style="display:inline-block;width:calc(100% - 51px);padding-right:10px;text-align: right">
          <a-button @click="toggleScreen" icon="appstore" style="height:20px;width:20px;border:0px"></a-button>
        </span>
      </div>
    </template>

    <a-spin :spinning="confirmLoading">
      <a-form :form="form" >

        <a-form-item label="商户号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input placeholder="请输入商户号" v-decorator="[ 'merchantId',{ rules: [{ required: true, message: '请输入商户号' }]}]" :readOnly="!!model.id"/>
        </a-form-item>

        <a-form-item label="币种" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select v-decorator="[ 'currency',{ rules: [{ required: true, message: '请输入币种' }]}]" placeholder="请选择币种" :getPopupContainer= "(target) => target.parentNode" >
            <a-select-option :value="'HUOBI_ERC20_USDT'">HUOBI ERC-20 USDT</a-select-option>
            <a-select-option :value="'USDT'">ERC-20 USDT</a-select-option>
            <a-select-option :value="'RMB'">RMB</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item v-if="true" label="支付通道" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select v-decorator="[ 'channelId',{ rules: [{ required: true, message: '请输入支付通道' }]}]" placeholder="请选择支付通道" :getPopupContainer= "(target) => target.parentNode">
            <a-select-option v-for="(item, index) in channelList" :key="index" :value="item.id">{{item.channelName}}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="支付限额下限*100" :labelCol="labelCol" :wrapperCol="wrapperCol" >
          <a-input type="number" placeholder="请输入支付限额上限*100" v-decorator="[ 'payMinLimit',{ rules: [{ required: true, message: '请输入支付限额上限' }]}]" />
        </a-form-item>

        <a-form-item label="支付限额上限*100" :labelCol="labelCol" :wrapperCol="wrapperCol" >
          <a-input type="number" placeholder="请输入支付限额下限*100" v-decorator="[ 'payMaxLimit',{ rules: [{ required: true, message: '请输入支付限额下限' }]}]" />
        </a-form-item>

        <a-form-item label="代付限额下限*100" :labelCol="labelCol" :wrapperCol="wrapperCol" >
          <a-input type="number" placeholder="请输入代付限额下限*100" v-decorator="[ 'agentpayMinLimit',{ rules: [{ required: true, message: '请输入代付限额下限' }]}]" />
        </a-form-item>

        <a-form-item label="代付限额上限*100" :labelCol="labelCol" :wrapperCol="wrapperCol" >
          <a-input type="number" placeholder="请输入代付限额上限*100" v-decorator="[ 'agentpayMaxLimit',{ rules: [{ required: true, message: '请输入代付限额上限' }]}]" />
        </a-form-item>

        <a-form-item label="支付费率*100(%)" :labelCol="labelCol" :wrapperCol="wrapperCol" >
          <a-input type="number" placeholder="请输入支付费率*100" v-decorator="[ 'payFeeRate',{ rules: [{ required: true, message: '请输入支付费率' }]}]" />
        </a-form-item>

        <a-form-item label="代付手续费*100" :labelCol="labelCol" :wrapperCol="wrapperCol" >
          <a-input type="number" placeholder="请输入代付手续费*100(每笔)" v-decorator="[ 'agentpayFee',{ rules: [{ required: true, message: '请输入代付手续费' }]}]" />
        </a-form-item>

      </a-form>
    </a-spin>
    <div class="drawer-bootom-button" v-show="!disableSubmit">
      <a-popconfirm title="确定放弃编辑？" @confirm="handleCancel" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm>
      <a-button @click="handleSubmit" type="primary" :loading="confirmLoading">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
  import pick from 'lodash.pick'
  import moment from 'moment'
  import Vue from 'vue'
  import {ACCESS_TOKEN} from "@/store/mutation-types"
  import {addMerchantService, editMerchantService,channelList} from '@/api/api'

  export default {
    name: "MerchantserviceModal",
    components: {
    },
    data () {
      return {
        modalWidth:900,
        drawerWidth:800,
        modaltoggleFlag:true,
        confirmDirty: false,
        disableSubmit:false,
        title:"操作",
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        uploadLoading:false,
        confirmLoading: false,
        headers:{},
        channelList: [],
        form:this.$form.createForm(this),
      }
    },
    created () {
      const token = Vue.ls.get(ACCESS_TOKEN);
      this.headers = {"X-Access-Token":token}
      this.getChannelList();
    },
    computed:{
    },
    methods: {

      //窗口最大化切换
      toggleScreen(){
        if(this.modaltoggleFlag){
          this.modalWidth = window.innerWidth;
        }else{
          this.modalWidth = 800;
        }
        this.modaltoggleFlag = !this.modaltoggleFlag;
      },
      add () {

        this.edit({activitiSync:'1'});
      },
      edit (record) {
        this.resetScreenSize(); // 调用此方法,根据屏幕宽度自适应调整抽屉的宽度
        let that = this;
        this.form.resetFields();
        this.model = Object.assign({}, record);
        that.visible = true;
        that.$nextTick(() => {
          that.form.setFieldsValue(pick(this.model,'merchantId','currency','channelId','payMinLimit','payMaxLimit','agentpayMinLimit','agentpayMaxLimit','payFeeRate','agentpayFee'))
        });
      },
      close () {
        this.$emit('close');
        this.visible = false;
        this.disableSubmit = false;
      },
      moment,
      getChannelList(){
        var param = {token:Vue.ls.get(ACCESS_TOKEN)};
        channelList(param).then((res)=>{
          if(res.success){
            this.channelList = res.result;
          }else{
          }
        });

      },
      handleSubmit () {
        const that = this;
        // 触发表单验证
        this.form.validateFields((err, values) => {
          if (!err) {
            that.confirmLoading = true;
            let formData = Object.assign(this.model, values);
            formData.token = Vue.ls.get(ACCESS_TOKEN);
            let obj;
            if(!this.model.id){
              formData.id = this.model.id;
              obj=addMerchantService(formData);
            }else{
              obj=editMerchantService(formData);
            }
            obj.then((res)=>{
              if(res.success){
                that.$message.success(res.result.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.result.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
              that.close();
            })

          }
        })
      },
      handleCancel () {
        this.close()
      },
      handleConfirmBlur  (e) {
        const value = e.target.value;
        this.confirmDirty = this.confirmDirty || !!value
      },
      resetScreenSize(){
        let screenWidth = document.body.clientWidth;
        if(screenWidth < 500){
          this.drawerWidth = screenWidth;
        }else{
          this.drawerWidth = 700;
        }
      }
    }
  }
</script>

<style scoped>
  .avatar-uploader > .ant-upload {
    width:104px;
    height:104px;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

  .ant-table-tbody .ant-table-row td{
    padding-top:10px;
    padding-bottom:10px;
  }

  .drawer-bootom-button {
    position: absolute;
    bottom: -8px;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    left: 0;
    background: #fff;
    border-radius: 0 0 2px 2px;
  }
</style>