<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">

          <a-col :md="6" :sm="16">
            <a-form-item label="提交时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-range-picker
                v-model="queryParam.createDate"
                format="YYYY-MM-DD"
                :placeholder="['开始日期', '结束日期']"
                @change="onDateChange"
                @ok="onDateOk"
              />
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item label="商户号">
              <a-input v-model="queryParam.merchantNo"></a-input>
            </a-form-item>
          </a-col>

          <template v-if="toggleSearchStatus">
            <a-col :md="6" :sm="8">
              <a-form-item label="收款方户名">
                <a-input v-model="queryParam.accountName"></a-input>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="8">
              <a-form-item label="交易号">
                <a-input  v-model="queryParam.agentpayNo"></a-input>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="8">
              <a-form-item label="状态">
                <a-select placeholder="状态" v-model="queryParam.status">
                  <a-select-option value="unsubmitted">待确认</a-select-option>
                  <a-select-option value="pending">待处理</a-select-option>
                  <a-select-option value="processing">处理中</a-select-option>
                  <a-select-option value="success">成功</a-select-option>
                  <a-select-option value="fail">失败</a-select-option>
                  <a-select-option value="cancel">已取消</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </template>

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">搜索</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
              <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? '收起' : '展开' }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <!-- table区域-begin -->
    <a-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="ipagination"
      :loading="loading"
      @change="handleTableChange">

      !-- 状态渲染模板 -->
      <template slot="status" slot-scope="status">
        <a-tag v-if="status=='success'" color="green">成功</a-tag>
        <a-tag v-if="status=='unsubmitted'" color="geekblue">待确认</a-tag>
        <a-tag v-if="status=='pending'" color="orange">待处理</a-tag>
        <a-tag v-if="status=='processing' || status=='error'" color="orange">处理中</a-tag>
        <a-tag v-if="status=='fail'" color="red">失败</a-tag>
        <a-tag v-if="status=='cancel'" color="red">已取消</a-tag>
      </template>

      <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
        <div style="margin-bottom: 5px">
          <a-badge style="vertical-align: middle;"/><span style="vertical-align: middle;">批次号：{{record.batchNo}}</span>
        </div>
        <div style="margin-bottom: 5px">
          <a-badge style="vertical-align: middle;"/><span style="vertical-align: middle;">币种：{{record.currency}}</span>
        </div>
        <div  style="margin-bottom: 5px">
          <a-badge style="vertical-align: middle;"/><span style="vertical-align: middle;">银行信息：{{record.bankName}}{{record.bankBranchName}}</span>
        </div>
        <div style="margin-bottom: 5px">
          <a-badge style="vertical-align: middle;"/><span style="vertical-align: middle;">备注：{{record.withfrawRemark}}</span>
        </div>
        <div v-if="record.status == 'success'" style="margin-bottom: 5px">
          <a-badge style="vertical-align: middle;"/><span style="vertical-align: middle;">完成时间：{{record.tradeDate}}</span>
        </div>
        <div v-if="record.status == 'success'" style="margin-bottom: 5px">
          <a-badge style="vertical-align: middle;"/><span style="vertical-align: middle;">反馈原因：{{record.reason}}</span>
        </div>
      </div>
    </a-table>
  </a-card>
</template>

<script>
  import Vue from 'vue'
  import {filterObj} from '@/utils/util';
  import {ListMixin} from '@/mixins/ListMixin'
  import Ellipsis from '@/components/Ellipsis'
  import {ACCESS_TOKEN } from "@/store/mutation-types"

  export default {
    name: "TradeList",
    mixins:[ListMixin],
    components: {
      Ellipsis,
    },
    data () {
      return {
        // 查询条件
        queryParam: {
          createDate:[],
          merchantNo:'',
          accountName:'',
          agentpayNo:'',
          status:''
        },
        // 表头
        columns: [
          {
            title: '交易号',
            dataIndex: 'agentpayNo',
            align:"left",
          },
          {
            title: '通道交易号',
            dataIndex: 'channelTradeNo',
            align:"center",
          },
          {
            title: '通道名称',
            dataIndex: 'channelName',
            align:"center",
          },
          {
            title: '收款方户名',
            dataIndex: 'accountName',
            align:"center",
          },
          {
            title: '银行名称',
            dataIndex: 'bankName',
            align:"center",
          },
          {
            title: '银行账号',
            dataIndex: 'bankAccountNum',
            align:"center",
          },
          {
            title: '付款金额',
            dataIndex: 'amount',
            align:"center",
          },
          {
            title: '状态',
            dataIndex: 'status',
            align:"center",
            scopedSlots: { customRender: 'status' },
          },
          {
            title: '创建时间',
            dataIndex: 'createDate',
            align:"center",
          },
          {
            title: '完成时间',
            dataIndex: 'tradeDate',
            align:"center",
          }
        ],
        labelCol: {
          xs: { span: 1 },
          sm: { span: 2 },
        },
        wrapperCol: {
          xs: { span: 10 },
          sm: { span: 16 },
        },
        url: {
          list: "/sys/agentpay/withdrewList",
        },
      }
    },
    methods: {
      getQueryParams(){
        var param = Object.assign({}, this.queryParam,this.isorter);
        param.pageNo = this.ipagination.current;
        param.pageSize = this.ipagination.pageSize;
        delete param.createDate; // 时间参数不传递后台
        if (this.superQueryParams) {
          param['superQueryParams'] = encodeURI(this.superQueryParams)
          param['superQueryMatchType'] = this.superQueryMatchType
        }
        return filterObj(param);
      },

      // 重置
      searchReset(){
        var that = this;
        that.queryParam = {}; //清空查询区域参数
        that.loadData(this.ipagination.current);
      },
      onDateChange: function (value, dateString) {
        console.log(dateString[0],dateString[1]);
        this.queryParam.createTimeBegin=dateString[0] + ' 00:00:00';
        this.queryParam.createTimeEnd=dateString[1] + ' 23:59:59';
      },
      onDateOk(value) {
        console.log(value);
      },
    }
  }
</script>