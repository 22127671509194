<template>
  <a-drawer
    :title="title"
    :maskClosable="false"
    :width="drawerWidth"
    placement="right"
    :closable="true"
    @close="handleCancel"
    :visible="visible"
    style="height: 100%;overflow: auto;padding-bottom: 53px;">

    <template slot="title">
      <div style="width: 100%;">
        <span>{{ title }}</span>
        <span style="display:inline-block;width:calc(100% - 51px);padding-right:10px;text-align: right">
          <a-button @click="toggleScreen" icon="appstore" style="height:20px;width:20px;border:0px"></a-button>
        </span>
      </div>

    </template>

    <a-spin :spinning="confirmLoading">
      <a-form :form="form" >

        <a-form-item label="商户号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input  v-decorator="[ 'merchantNo']" :readOnly="visible"  />
        </a-form-item>

        <a-form-item label="币种" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input  v-decorator="[ 'currency']" :readOnly="visible" />
        </a-form-item>

        <a-form-item label="转入钱包地址" :labelCol="labelCol" :wrapperCol="wrapperCol" >
          <a-input  v-decorator="[ 'toAddress']" :readOnly="visible"  />
        </a-form-item>

        <a-form-item label="金额" :labelCol="labelCol" :wrapperCol="wrapperCol" >
          <a-input v-decorator="[ 'amount']" :readOnly="visible"  />
        </a-form-item>

        <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol" >
          <a-input v-decorator="[ 'remark']" :readOnly="visible"  />
        </a-form-item>

        <!--<a-form-item label="交易hash" :labelCol="labelCol" :wrapperCol="wrapperCol" >
          <a-input  placeholder="交易hash" v-decorator="[ 'tokenTradeHash',{ rules: [{ required: true, message: '交易hash' }]}]" />
        </a-form-item>-->

      </a-form>
    </a-spin>
    <div class="drawer-bootom-button" v-show="!disableSubmit">
      <a-popconfirm title="确定放弃编辑？" @confirm="handleCancel" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm>
      <a-button @click="handleSubmit" type="primary" :loading="confirmLoading">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
  import pick from 'lodash.pick'
  import moment from 'moment'
  import Vue from 'vue'
  import {ACCESS_TOKEN} from "@/store/mutation-types"
  import {processAgentpay} from '@/api/api'

  export default {
    name: "AgentpayHBProcessModal",
    components: {
    },
    data () {
      return {
        modalWidth:900,
        drawerWidth:800,
        modaltoggleFlag:true,
        confirmDirty: false,
        disableSubmit:false,
        title:"操作",
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        uploadLoading:false,
        confirmLoading: false,
        headers:{},
        form:this.$form.createForm(this),
      }
    },
    created () {
      const token = Vue.ls.get(ACCESS_TOKEN);
      this.headers = {"X-Access-Token":token}

    },
    computed:{
    },
    methods: {

      //窗口最大化切换
      toggleScreen(){
        if(this.modaltoggleFlag){
          this.modalWidth = window.innerWidth;
        }else{
          this.modalWidth = 800;
        }
        this.modaltoggleFlag = !this.modaltoggleFlag;
      },
      add () {

        this.edit({activitiSync:'1'});
      },
      edit (record) {
        this.resetScreenSize(); // 调用此方法,根据屏幕宽度自适应调整抽屉的宽度
        let that = this;
        this.form.resetFields();
        this.model = Object.assign({}, record);
        that.visible = true;
        that.$nextTick(() => {
          that.form.setFieldsValue(pick(this.model,'merchantNo','currency','toAddress','amount','remark'))
        });
      },
      close () {
        this.$emit('close');
        this.visible = false;
        this.disableSubmit = false;
      },
      moment,
      handleSubmit () {
        const that = this;
        // 触发表单验证
        this.form.validateFields((err, values) => {
          if (!err) {
            that.confirmLoading = true;
            let formData = Object.assign(this.model, values);
            formData.token = Vue.ls.get(ACCESS_TOKEN);
            let obj;
            if(this.model.id){
              formData.id = this.model.id;
              obj=processAgentpay(formData);
            }
            obj.then((res)=>{
              if(res.success){
                that.$message.success(res.result.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.result.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
              that.close();
            })
            that.confirmLoading = false;
          }
        })
      },
      handleCancel () {
        this.close()
      },
      handleConfirmBlur  (e) {
        const value = e.target.value;
        this.confirmDirty = this.confirmDirty || !!value
      },
      resetScreenSize(){
        let screenWidth = document.body.clientWidth;
        if(screenWidth < 500){
          this.drawerWidth = screenWidth;
        }else{
          this.drawerWidth = 700;
        }
      }
    }
  }
</script>

<style scoped>
  .avatar-uploader > .ant-upload {
    width:104px;
    height:104px;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

  .ant-table-tbody .ant-table-row td{
    padding-top:10px;
    padding-bottom:10px;
  }

  .drawer-bootom-button {
    position: absolute;
    bottom: -8px;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    left: 0;
    background: #fff;
    border-radius: 0 0 2px 2px;
  }
</style>