<template>
  <icon-selector></icon-selector>
</template>

<script>
  import IconSelector from '@/components/IconSelector'

  export default {
    components: {IconSelector},
    name: "IconsTab"
  }
</script>