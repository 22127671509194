<template>
  <a-list>
    <a-list-item>

    </a-list-item>
  </a-list>
</template>

<script>
  import AList from 'ant-design-vue/es/list'
  import AListItem from 'ant-design-vue/es/list/Item'

  export default {
    name: "Article",
    components: {
      AList,
      AListItem
    }
  }
</script>

<style scoped>

</style>