<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">

          <a-col :md="6" :sm="8">
            <a-form-item label="UID">
              <a-input v-model="queryParam.uid"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item label="使用状态">
              <a-select placeholder="使用状态" v-model="queryParam.useStatus">
                <a-select-option value="available">可用</a-select-option>
                <a-select-option value="lock">锁定</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置查询</a-button>
            </span>
          </a-col>

        </a-row>
      </a-form>
    </div>

    <!-- 操作按钮区域 -->
    <div class="table-operator" style="border-top: 5px">
      <a-button @click="handleAdd" type="primary" icon="plus">添加</a-button>
    </div>


    <!-- table区域-begin -->
    <div>
      <a-table
        ref="table"
        bordered
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        @change="handleTableChange">

        <template slot="useStatus" slot-scope="useStatus">
          <a-tag v-if="useStatus=='available'" color="green">可用</a-tag>
          <a-tag v-if="useStatus=='lock'" color="red">锁定</a-tag>
        </template>

      </a-table>
    </div>
    <!-- table区域-end -->
    <sub-user-modal ref="modalForm" @ok="modalFormOk"></sub-user-modal>

  </a-card>
</template>

<script>
  import SubUserModal from './modules/SubUserModal'
  import {getFileAccessHttpUrl} from '@/api/manage';
  import {ListMixin} from '@/mixins/ListMixin'
  import {filterObj} from '@/utils/util';
  import Vue from 'vue'
  import {ACCESS_TOKEN } from "@/store/mutation-types"

  export default {
    name: "SubUserList",
    mixins: [ListMixin],
    components: {
      SubUserModal
    },
    data() {
      return {
        description: '子账户管理',
        queryParam: {},
        columns: [
          {
            title: '母账户名称',
            dataIndex: 'parentUserName',
            align:"center",
          },
          {
            title: '子账户名称',
            dataIndex: 'userName',
            align:"center",
          },
          {
            title: '子账户UID',
            dataIndex: 'userId',
            align:"center",
          },
          {
            title: '充币地址',
            dataIndex: 'address',
            align:"center",
          },
          {
            title: '使用次数',
            dataIndex: 'useTime',
            align:"center",
          },
          /*{
            title: '火币余额',
            dataIndex: 'balance',
            align:"center",
          },*/
          {
            title: '使用状态',
            dataIndex: 'useStatus',
            scopedSlots: { customRender: 'useStatus' },
            align:"center",
          },
          {
            title: '锁定时间',
            dataIndex: 'lockTime',
            align:"center",
          },
          {
            title: '创建时间',
            dataIndex: 'createDate',
            align:"center",
          }
        ],
        url: {
          list: "/sys/huobi/subUserList",
        },
      }
    },
    computed: {
    },
    methods: {
      getQueryParams(){
        var param = Object.assign({}, this.queryParam,this.isorter);
        param.token = Vue.ls.get(ACCESS_TOKEN);
        param.pageNo = this.ipagination.current;
        param.pageSize = this.ipagination.pageSize;
        if (this.superQueryParams) {
          param['superQueryParams'] = encodeURI(this.superQueryParams)
          param['superQueryMatchType'] = this.superQueryMatchType
        }
        return filterObj(param);
      },

      passwordModalOk() {
        //TODO 密码修改完成 不需要刷新页面，可以把datasource中的数据更新一下
      }
    }

  }
</script>