<template>
  <div>
    <a-form :form="form" style="max-width: 500px; margin: 40px auto 0;">
      <a-alert
        :closable="true"
        message="After the transfer is confirmed, the funds will be directly credited to the other party's account and cannot be returned."
        style="margin-bottom: 24px;"
      />
      <a-form-item
        label="To Address"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        class="stepFormText"
      >
        {{toAddress}}
      </a-form-item>
      <a-form-item
        label="Amount"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        class="stepFormText"
      >
        {{amount}} USDT
      </a-form-item>
      <a-form-item
        label="Remarks"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        class="stepFormText"
      >
        {{remark}}
      </a-form-item>
      <a-divider />
      <a-form-item
        label="pay password"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        class="stepFormText"
      >
        <a-input
          type="password"
          style="width: 80%;"
          v-decorator="['payPassword', { rules: [{required: true, message: 'Please enter payment password'}] }]" />
      </a-form-item>
      <a-form-item :wrapperCol="{span: 19, offset: 5}">
        <a-button :loading="loading" type="primary" @click="nextStep">Submit</a-button>
        <a-button style="margin-left: 8px" @click="prevStep">Previous step</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
  import { submintAgentpay} from '@/api/api'
  import Vue from 'vue'
  import {ACCESS_TOKEN } from "@/store/mutation-types"
  import md5 from 'md5';

  export default {
    name: 'Step2',
    data () {
      return {
        labelCol: { lg: { span: 5 }, sm: { span: 5 } },
        wrapperCol: { lg: { span: 19 }, sm: { span: 19 } },
        form: this.$form.createForm(this),
        loading: false,
        timer: 0,
        toAddress:'',
        amount:null,
        remark:''
      }
    },
    created () {
      const fileListInfo = sessionStorage.getItem('submitAgentPayParams');
      const dataInfo = JSON.parse(fileListInfo);
      this.toAddress = dataInfo.toAddress;
      this.amount = dataInfo.amount;
      this.remark = dataInfo.remark;
    },
    methods: {
      nextStep () {
        const that = this
        const { form: { validateFields } } = this
        that.loading = true
        validateFields((err, values) => {
          if (!err) {
            values.token = Vue.ls.get(ACCESS_TOKEN);
            values.payPassword = md5(values.payPassword);
            values.tokenTo = this.toAddress;
            values.amount = this.amount;
            values.reamrk = this.remark;
            values.currency = 'USDT';
            submintAgentpay(values).then((res)=>{
              if(res.success){
                that.loading = false
                that.$emit('nextStep')
              }else{
                // Invalid data
                this.$message.warning('Invalid data');
                that.loading = false
              }
            });
          } else {
            that.loading = false
          }
        })
      },
      prevStep () {
        this.$emit('prevStep')
      }
    },
    beforeDestroy () {
      clearTimeout(this.timer)
    }
  }
</script>

<style lang="less" scoped>
  .stepFormText {
    margin-bottom: 24px;
    .ant-form-item-label,
    .ant-form-item-control {
      line-height: 22px;
    }
  }
</style>